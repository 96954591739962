.ClaimFormBlock__wrapper {
  // intentionally blank
}

.ClaimFormBlock {
  // intentionally blank
}

.ClaimFormBlock__modal__panel {
  @include media-breakpoint-up(sm) {
    min-height: auto;
  }

  max-height: calc(100vh - (var(--bs-gutter-x, 0.75rem) * 2));

  display: flex;
  flex-direction: column;
}

.ClaimFormBlock__modal__title {
  flex: 0 0 auto;
  margin-bottom: 1rem;
}

.ClaimFormBlock__modal__warning {
  text-align: center;

  button {
    margin: 0rem 0.5rem;
  }
}

.ClaimFormBlock__form {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
}

.ClaimFormBlock__loginButton {
  padding-top: 3rem;
}
