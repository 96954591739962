@use "./variables" as page-blocks;

@import "./components/ContentBlocks/DividerBlock/DividerBlock";
@import "./components/ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock";
@import "./components/ContentBlocks/HeaderButtonBlock/HeaderButtonBlock";
@import "./components/ContentBlocks/TextColumnsBlock/TextColumnsBlock";
@import "./components/ContentBlocks/MapBlock/MapBlock";
@import "./components/ContentBlocks/TextWithFactsBlock/TextWithFactsBlock";
@import "./components/FormBlocks/ClaimFormBlock/ClaimFormBlock";
@import "./components/FormBlocks/ContactFormBlock/ContactFormBlock";
@import "./components/FormBlocks/AgentFormBlock/AgentFormBlock";
@import "./components/FormBlocks/PaymentFormBlock/PaymentFormBlock";
@import "./components/HeroBlocks/GlobalHeroBlock";
@import "./components/HeroBlocks/HomeHeroBlock";
@import "./components/ImageBlocks/ImageTitleBlock/ImageTitleBlock";
@import "./components/ImageBlocks/SliderBlock/SliderBlock";
@import "./components/ImageBlocks/TileBlock/TileBlock";
@import "./components/ImageBlocks/InfographicBlock/InfographicBlock";
@import "./components/AccordionBlocks/ProductBlock/ProductBlock";
@import "./components/AccordionBlocks/FaqAccordionBlock/FaqAccordionBlock";

.PageBlocks {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    padding-top: page-blocks.$gap-xs;
    gap: page-blocks.$gap-xs;
  }

  @include media-breakpoint-up(sm) {
    padding-top: page-blocks.$gap;
    gap: page-blocks.$gap;
  }
}
