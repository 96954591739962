@use "sass:math";
@use "family.scss/source/src/family" as family;

.InfographicBlock__wrapper {
  // intentionally blank
}

.InfographicBlock__column__wrapper {
  margin: calc(var(--bs-gutter-x, 0.75rem) * -1);
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-evenly;
}

.InfographicBlock__column {
  flex: 0 1 auto;
  padding: 0;
  padding-top: 3rem;
  text-align: center;

  @include media-breakpoint-down(sm) {
    width: (math.div(11, 12) * 100%) !important;
  }

  @include media-breakpoint-only(sm) {
    width: (math.div(9, 12) * 100%) !important;
  }

  @include family.at-most(3) {
    @include media-breakpoint-up(md) {
      width: (math.div(4, 12) * 100%) !important;
    }
  }

  @include family.at-least(5) {
    @include media-breakpoint-only(md) {
      width: (math.div(6, 12) * 100%) !important;
    }

    @include media-breakpoint-only(lg) {
      width: (math.div(5, 12) * 100%);
    }

    @include media-breakpoint-up(xl) {
      width: (math.div(2, 12) * 100%);
    }
  }

  img {
    max-height: 80px;
    object-fit: contain;
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    font-size: 16px;
    font-weight: "500";
  }

  .InfographicBlock__number__wrapper {
    //blank
  }

  .InfographicBlock__number {
    display: flex;
    padding-bottom: 1rem;

    p {
      height: 31px;
      width: 31px;
      background-color: $color-white;
      border: 1px #5cacc6 solid;
      text-align: center;
      border-radius: 100%;
      line-height: 31px;
      vertical-align: middle;
      color: #5cacc6;
      font-weight: 600;
      margin: 0;
    }

    .dashed {
      border-top: 1px #5cacc6 dashed;
      flex-grow: 1;
      align-self: center;

      @include media-breakpoint-down(xl) {
        border: transparent;
      }
    }
  }

  &:first-child {
    .dashed:first-child {
      border: transparent;
    }
  }

  &:last-child {
    .dashed:last-child {
      border: transparent;
    }
  }
}
