@use "./variables" as logo;

.Logo__wrapper {
  display: inline-block;

  padding-top: logo.$padding;
  padding-bottom: logo.$padding;

  &,
  &:hover {
    color: $color-black;
  }

  &.areMainLinksVisible {
    color: $color-secondary;
  }
}

.Logo {
  display: inline-block;
  width: auto;

  @include media-breakpoint-down(sm) {
    height: logo.$height-xs;
  }

  @include media-breakpoint-up(sm) {
    height: logo.$height;
  }

  fill: currentColor;
}
